<template>
  <div
    class="sign-overview"
    v-if="!loading"
  >
    <block-header>
      <div>
        <span class="header-info">实时数据</span>
        <span>今天（{{ today }}）</span>
        <span>签到及健康检测</span>
      </div>
      <!-- <div>完整数据 >></div> -->
    </block-header>
    <div class="sign-overview-chart">
      <div class="chart-box">
        <div class="sign-overview-chart-item sign-overview-left">
          <p class="item-title">
            实时签到人数
          </p>
          <p>
            <span class="item-number item-number-todaysign">{{ todaySignedCount }}</span>
            <span>个</span>
          </p>
        </div>
        <div class="sign-overview-chart-item sign-overview-right">
          <p>签到率</p>
          <div class="progress">
            <span class="progress-percent">{{ signedRate.value ? `${signedRate.value}%` : 0 }}</span>
            <div
              class="progress-item"
              :style="{width:`${signedRate.value}%`}"
            />
          </div>
        </div>
      </div>
      <div class="chart-box">
        <div class="sign-overview-chart-item sign-overview-left">
          <p class="item-title">
            学费宝订单签到人数
          </p>
          <p>
            <span class="item-number item-number-xfb">{{ xfbSignedCount }}</span>
            <span>个</span>
          </p>
        </div>
        <div class="sign-overview-chart-item sign-overview-right">
          <p>学费宝订单签到率</p>
          <div class="progress">
            <span class="progress-percent">{{ xfbSignedRate.value ? `${xfbSignedRate.value}%` : 0 }}</span>
            <div
              class="progress-item"
              :style="{width:`${xfbSignedRate.value}%`}"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BlockHeader from 'src/components/BlockHeader.vue';
import dayjs from 'dayjs'
import Api from 'src/api/index.js';

export default {
    name: "SignOverview",
    props: {
        kindergartenId: {
            type: String,
            default: ""
        },
    },
    data() {
        return {
            signedRate: {
                value: 0
            },
            todaySignedCount:0,
            xfbSignedCount: 0,
            xfbSignedRate: {
                value: 0
            },
            loading: false
        }
    },
    components: {
        BlockHeader,
    },

    computed: {
        today() {
            return dayjs().format('YYYY-MM-DD');
        },
        style() {
            return "width:160px;height:60px;"
        }
    },

    created() {
        this.getKindergartenSignOverview();
    },
    methods: {
        getKindergartenSignOverview() {
            this.loading = true;
            Api.getKindergartenSignOverview({
                date_format: dayjs().format('YYYY-MM-DD'),
                kindergarten_id: this.kindergartenId
            }).then(({data}) => {
                this.signedRate.value = data.signedRate || 0;
                this.xfbSignedRate.value = data.xfbSignedRate || 0;
                this.todaySignedCount = data.todaySignedCount || 0;
                this.xfbSignedCount = data.xfbSignedCount || 0;
            }).finally(() => {
              this.loading = false;
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.sign-overview {
  .block-header {
    height: 92px;
    line-height: 92px;
    color: rgba(0,158,255,1);
    .header-info {
      margin-right: 28px;
    }
  }
  .chart-box {
    margin-bottom: 24px;
    display: flex;
  }
  &-chart {
    // display: flex;
    // padding: 20px;
    &-item {
      display: inline-flex;
      height: 104px;
      flex-direction: column;
      justify-content: center;
      background: rgba(0,104,183,0.3);
      .item-title {
        margin: 20px 0;
      }
    }
  }
  &-left {
    align-items: center;
    width: 316px;
    margin-right: 22px;
    .item-number {
      font-size: 30px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      margin-right: 18px;
    }
    .item-number-todaysign {
      color: #9d4ee9;
    }

    .item-number-xfb {
      color: #08d1ea;;
    }
  }

  &-right {
    width: 626px;
    height: 105px;
    padding-left: 48px;
    .progress {
      width: 446px;
      background: rgba(48,62,140,1);
      position: relative;
      &-percent {
        position: absolute;
        right: 0;
        top: -18px;
      }

      &-item {
        height: 26px;
        background: linear-gradient(to right,#006fff 0%, #9e4ee9 100%);
      }
    }
  }
}
</style>
