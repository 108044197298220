<template>
  <div
    id="index"
    ref="appRef"
  >
    <div class="bg">
      <dv-loading v-if="loading">
        Loading...
      </dv-loading>
      <div
        v-else
        class="host-body"
      >
        <app-header />
        <p class="school-name">
          {{ baseInfo.name }}
        </p>
        <base-info :base-info="baseInfo" />
        <div class="sign-data">
          <sign-overview :kindergarten-id="kindergartenId" />
          <sign-list :kindergarten-id="kindergartenId" />
        </div>
        <sign-history :kindergarten-id="kindergartenId" />
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from 'src/components/Header.vue';
import drawMixin from "../utils/drawMixin";
import SignOverview from './components/SignOverview.vue';
import SignHistory from './components/SignHistory/Index.vue';
import SignList from './components/SignList.vue';
import BaseInfo from './components/BaseInfo.vue';
import Api from 'src/api/index.js';

export default {
  mixins: [ drawMixin ],
  data() {
    return {
      loading: false,
      baseInfo: {},
      kindergartenId: process.env.VUE_APP_KINDERGARTEN_ID
    }
  },
  components: {
    AppHeader,
    SignOverview,
    SignHistory,
    SignList,
    BaseInfo
  },

  created() {
    this.getKindergartenDetail();
  },

  methods: {
    getKindergartenDetail() {
        this.loading = true;
        Api.getKindergartenDetail({
            kindergarten_id: this.kindergartenId
        }).then(({data}) => {
            this.baseInfo = data;
        }).finally(() => {
            this.loading = false;
        })
    },
  }
}
</script>

<style lang="scss" scoped>
#index {
  color: $base-font-color;
  width: 1920px;
  height: 1080px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: left top;
  overflow: hidden;
  font-size: 16px;

  .bg {
    width: 100%;
    height: 100%;
    padding: 16px 16px 0 16px;
    // background-image: url("../assets/image/bg.png");
    background-size: cover;
    background-position: center center;
    overflow: auto;
  }

  .school-name {
    height: 86px;
    line-height: 86px;
    font-size: 28px;
    color: #08D1EA;
  }
}
</style>
